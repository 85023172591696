import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import ReviewSection from "../components/review-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import SeoPageLogos from "../components/seo-page-logos";
import SeoWhatWeSolve from "../components/seo-what-we-solve";
import SeoWwaSection from "../components/seo-wwa-section";

const SEOLondonPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			jasonImg: wpMediaItem(title: { eq: "Jasoncorneslogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "SEARCH ENGINE-OPTIMISATION-IMAGE-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seymourImg: wpMediaItem(title: { eq: "Seymourslogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			videos: allWpVideo(
				filter: { videoFields: { seoPage: { in: "Web Design Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					videoFields {
						url
						seoPage
					}
					title
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			foehImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "SEO London" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "SEO London" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "SEO London",
				item: {
					url: `${siteUrl}/seo-london`,
				},
			},
		],
	};
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/seo-london`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SeoHero
						title={`SEO \n<span class="text-secondary">London</span>`}
						description={
							<span>
								Professional search engine optimisation techniques to help your
								<br className="d-none d-lg-block" />
								London based business achieve greater long term success
							</span>
						}
					/>
				</section>

				<SeoWwaSection
					spanLg={null}
					heading="Do you need SEO support from a trusted agency in London?"
					text={
						<div>
							<p>
								At RJM Digital, we enjoy collaborating closely with our clients
								regardless of what industries or markets they operate in.
								Whether you are a local London based business or an
								international organisation, we can bring great value to your
								website with our <strong>in-depth SEO campaigns</strong>.
							</p>
							<p>
								We understand the vital role your professionally designed
								website plays in the success of your company in the digital
								world. But having structured and proven SEO campaigns are also
								crucial. Once you begin working with RJM Digital you will soon
								realise how we prioritise our clients needs and wants in
								everything we do.
							</p>
							<p>
								A strong SEO strategy is crucial for increasing the amount of
								conversions, enhancing your website's visibility, and boosting
								the number leads your London business receives. By adhering to
								Google's best practices, we ensure that the{" "}
								<strong>SEO work</strong> we do will benefit your London company
								site in a cost-effective way that will last{" "}
								<strong>long-term</strong>. With daily checks on your sites
								performance, we ensure your website's SEO is always up to date.
							</p>
							<p>
								Our results-oriented expert team is dedicated to helping your
								London business grow from day one of working with RJM Digital,
								your <strong>new SEO agency.</strong> 
							</p>
						</div>
					}
					buttonText="Get in touch"
					buttonUrl="/contact-us"
					noImages
				/>
				<section className="pb-5 pb-lg-7">
					<SeoPageLogos heading={null} page="SEO London" />
				</section>

				<SeoWhatWeSolve
					heading={<span>Experts in SEO for London companies</span>}
					image={data.foehImg.gatsbyImage}
					imageAlt={data.foehImg.altText}
					imgHeight="35rem"
					last
					text={
						<div>
							<h3 className="text-primary fs-2 ">
								Our <span className="text-secondary">keyword research</span>{" "}
								sevices
							</h3>
							<p>
								To be able to start the SEO work for your site, you must begin
								with identifying the relevant and up to date keywords that you
								want your London website to rank for on Google. Then you will
								need to optimise your website for these keywords to allow your
								site to rank on the <strong>first page</strong> of the search
								engine results. We can provide you with keyword services to find
								the most appropriate keywords for your{" "}
								<strong>London business</strong> or we can give you some
								training and resources for you to take this step in-house
								instead of outsourcing. 
							</p>
							<h3 className="mt-5 text-primary fs-2">
								Proven <span className="text-secondary">SEO London</span>{" "}
								strategies
							</h3>
							<p>
								Even though <strong>over 90% of total searches</strong>{" "}
								performed online comes from Google, RJM Digital creates your SEO
								London strategy so your SEO optimised website can be found at
								the top of <strong>any search engine</strong>, including Bing,
								DuckDuckGo, Yandex, and more.
							</p>
							<h3 className="mt-5 text-primary fs-2">
								Boost you knowledge with a{" "}
								<span className="text-secondary">free SEO Audit</span>{" "}
							</h3>
							<p>
								Increase your knowledge on SEO with your free SEO audit from RJM
								Digital. We will discover the benefits that your company will
								experience after having a website that has been fully SEO
								optimised and the advantages to having your website in the top
								positions on the search engine results pages, for example, an
								increase in the amount of what benefits organic traffic your
								business will receive, ultimately leading to an increase in the
								number of enquiries your business achieves too. Our SEO team
								will then explore what else is needed to help your website rank
								higher on Google and calculate your Return on Investment.
							</p>
						</div>
					}
				/>
				<section
					id="results"
					style={{
						background:
							"transparent linear-gradient(52deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="pb-5  pb-lg-7"
				>
					<Container>
						<Row className="py-5 align-items-center py-xl-0">
							<Col lg={{ span: 8, order: "last" }}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.seoImg.gatsbyImage}
										alt={data.seoImg.altText}
									/>
								</div>
							</Col>
							<Col lg={4} className=" mt-lg-0">
								<h2 className="mb-4 text-white display-5">
									Results driven SEO agency in London
								</h2>
								<p className="text-white">
									RJM Digital have years of experience working with many
									different London businesses in a variety of industries,
									including charities, helping them through our expert SEO
									knowledge and resources to achieve greater web results than
									they used to.{" "}
								</p>
								<p className="text-white">
									Google crawls and indexes websites, but the way in which they
									do that is always changing. This means that our experienced
									SEO team are always keeping up to date with SEO training,
									workshops, and resources, to ensure our clients are always
									getting the best results from their SEO.{" "}
								</p>
								<p className="text-white">
									Here are some of our customers achievements:  
								</p>
								<Button
									className="px-4 py-2 my-4  w-100 w-md-auto fs-5 primary-link"
									variant="white"
									as={Link}
									to="/contact-us"
								>
									Get in touch
								</Button>
							</Col>
						</Row>
						<Row className="py-5 mt-4 align-items-center our-results-seo mt-lg-0 py-lg-0 gy-5 gy-lg-0">
							<Col className="p-4 text-center" xs={12} lg={true}>
								<div className="p-4 bg-jason">
									<GatsbyImage
										className="mw-100 "
										image={data.jasonImg.gatsbyImage}
										alt={data.jasonImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="text-center ssp-bold text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">3</p>
								<h3 className="fs-5">Month ROI</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">
									200%
								</p>
								<h3 className="fs-5">Increase traffic</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">5x</p>
								<h3 className="fs-5">More enquiries</h3>
							</Col>
						</Row>
						<Row className="py-5 mt-5 align-items-center our-results-seo py-lg-0 gy-5 gy-lg-0">
							<Col xs={12} className="p-4 text-center" lg={true}>
								<div className="p-4 bg-black">
									<GatsbyImage
										className="mw-100 "
										image={data.seymourImg.gatsbyImage}
										alt={data.seymourImg.altText}
									/>
								</div>
							</Col>
							<Col lg={true}>
								<h2 className="text-center ssp-bold text-lg-start fs-3">
									Our results:
								</h2>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">5</p>
								<h3 className="fs-5">Locations at #1</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">50%</p>
								<h3 className="fs-5">Increase in CTR</h3>
							</Col>
							<Col className="text-center" lg={true}>
								<p className="pb-1 mb-0 ssp-bold text-primary display-4">2x</p>
								<h3 className="fs-5">More enquiries</h3>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection
						heading="Web Design Eastbourne Reviews"
						page="Our Web Services"
					/>
				</section>

				<section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<div className="iframe-container-new">
									<iframe
										className="responsive-iframe"
										src="https://www.youtube.com/embed/1fmeuE1eVUU"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
										loading="lazy"
									></iframe>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<h2 className="mb-5 text-primary fs-1">
									Recent Eastbourne Web Design blog articles
								</h2>
							</Col>
						</Row>
						<Row className="g-5 gx-lg-7 h-100">
							{data.allWpPost.nodes.map((blog, index) => (
								<Col style={{ minHeight: "100%" }} lg={6}>
									<div
										className="bg-white position-relative"
										style={{
											overflow: "hidden",
											borderRadius: "20px",
											boxShadow: "0px 3px 60px #00000029",
											minHeight: "100%",
										}}
									>
										<GatsbyImage
											image={blog.blogFields.featuredImage.gatsbyImage}
											alt={blog.blogFields.featuredImage.altText}
											className="w-100 seo-blog-image"
										/>
										<div className="p-4">
											<h2 className="mb-4 fs-4 pe-lg-10 text-primary ssp-bold">
												{blog.title}
											</h2>
											<div className="w-100" style={{ height: "25px" }}></div>
											<Link
												to={`/blog/${convertToSlug(blog.blogFields.category)}/${
													blog.slug
												}`}
												className="bottom-0 pb-4 med-grey-link ssp-bold position-absolute"
											>
												READ BLOG
											</Link>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Container>
				</section>

				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row>
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Book a discovery meeting
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2357497086928!2d-0.0886605!3d51.5272357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca671a6cfb1%3A0x3adb2930f9039227!2sKemp%20House%2C%20124%20City%20Rd%2C%20London%20EC1V%202NX!5e0!3m2!1sen!2suk!4v1666365578873!5m2!1sen!2suk" />
				<OSSection
					link1="/training"
					text1="TRAINING"
					link3="/web-design"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/>
			</Layout>
		</>
	);
};

export default SEOLondonPage;
